<script setup>
import { onMounted, ref, watch } from "vue";
import useCarriers from "@/hooks/useCarriers";
import { useToast } from "vue-toastification";
import c3api from "@/c3api";

const props = defineProps({
  title: { type: String, required: true },
  appt: { 
    type: Object, 
    required: true 
  },
  history: { 
    type: Array, 
    // required: true 
  },
  orderType: {
    type: String,
    required: true,
  }
});

const { fetchCarriers, carriers } = useCarriers();
const toast = useToast();
const selectedDate = ref(new Date());
const uploadingBol = ref(false);
const bolFileInput = ref(null);
let apptData = ref({
  door_id : "",
  carrier_id : "",
  window_date : "",
  start_time : "",
  bypass_ltl : false,
  ltl_slot : false,
})
const appt = ref({});

const dockDoors = ref([]);

onMounted(async () => {
  fetchCarriers();
  dockDoors.value = await getDoorDocks();

  if (props.appt) {
    Object.keys(apptData.value).forEach((key) => {
      apptData.value[key] = props.appt[key];
    });
  }
});

const getStateColor = (state) => {
  switch (state) {
    case "pending":
      return "secondary";
    case "missed":
      return "error";
    case "canceled":
      return "warning";
    case "active":
      return "success";
    case "completed":
      return "success";
    default:
      return "primary";
  }
}

const updateAppt = async () => {
  if (props.orderType === "inbound") {
    // handle inbound appointments
    console.log("inbound", apptData.value);
  } else if (props.orderType === "outbound") {
    // handle outbound appointments
    console.log("outbound", apptData.value);
  } else {
    // Add more types if needed
    toast.error("Invalid order type.");
  }
}

const uploadBol = async () => {
  if (!bolFileInput.value.files[0]) {
    toast.error("Please select a BOL file.");
    return;
  }
  if (props.orderType === "inbound") {
    // handle inbound uploads
    console.log("updateAppt", bolFileInput.value.files[0]);
  } else if (props.orderType === "outbound") {
    // handle outbound uploads
    console.log("updateAppt", bolFileInput.value.files[0]);
  } else {
    // Add more types if needed
    toast.error("Invalid order type.");
  }
}

watch(selectedDate, (newValue) => {
  if (newValue) {
    const date = new Date(newValue);
    apptData.value.window_date = `${String(date.getDate()).padStart(2, "0")}/${String(date.getMonth() + 1).padStart(2, "0")}/${date.getFullYear()}`;
  }
});

const getDoorDocks = async () => {
  try {
    const response = await c3api.get(`/dock_doors`);
    return response.data.data;
  } catch (error) {
    toast.error(error.response.data.data.join(". "));
  }
}
</script>

<template>
  <v-card class="mt-4 pa-2 w-auto">
    <v-card-title class="d-flex justify-space-between">
      <span>
        {{ props.title }} <v-chip :color="getStateColor(appt?.status)" size="x-small" variant="flat"  class="mr-1">{{ appt?.status ? appt?.status.toUpperCase() : "NEW"}}</v-chip>
      </span>
      <small v-if="appt?.id" class="text-disabled">{{appt?.id}}</small>
    </v-card-title>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <!-- TODO: Check if status is pending -->
        <v-card-text v-if="true">
          <v-autocomplete
            v-model="apptData.door_id"
            :items="dockDoors"
            item-title="name"
            item-value="id"
            density="compact"
            variant="outlined"
            hide-details
            label="Dock Door"
          />

          <v-autocomplete
            v-model="apptData.carrier_id"
            :items="carriers"
            class="my-4"
            item-title="name"
            item-value="id"
            density="compact"
            variant="outlined"
            hide-details
            label="Carrier"
          />
          
          <v-text-field
            v-model="apptData.window_date"
            density="compact"
            hide-details
            id="menu-activator"
            placeholder="dd/mm/yyyy"
            label="Date"
            variant="outlined"
            readonly
          />
          <v-menu
            :close-on-content-click="true"
            transition="scale-transition"
            offset-y
            min-width="auto"
            activator="#menu-activator"
          >
            <v-date-picker
              update:modelValue="() => {}"
              show-adjacent-months
              v-model="selectedDate"
            ></v-date-picker>
          </v-menu>

          <div v-if="apptData.ltl_slot"  class="my-4">
            <div v-if="apptData.ltl_slot && !apptData.bypass_ltl" class="ml-2">
              <p class="lead m-0">2-4pm LTL Appointment</p>
              <a href="javascript:false" @click="apptData.bypass_ltl = true">
                  Select Appointment Time
              </a>
            </div>
            <div v-else>
              <v-text-field 
                v-model="apptData.start_time" 
                class="mb-2"
                density="compact"
                hide-details="auto"
                label="Time"
                variant="outlined"
                type="time"
              />
              <span class="ml-2">
                <a href="javascript:false" @click="apptData.bypass_ltl = false">
                  Use LTL Appointment Slot
                </a>
              </span>
            </div>
          </div>

          <v-text-field
            v-else
            v-model="apptData.start_time"
            class="my-4"
            density="compact"
            hide-details="auto"
            label="Time"
            variant="outlined"
            type="time"
          />

          <!-- TODO pallets field is missing in the order -->
          <div class="d-flex ga-4 align-center ml-2">
            <h3>Pallets</h3>
            <div>0 pallet(s)</div>
          </div>

          <!-- v-if="appt.order_type_id == 2" -->
          <!-- TODO -->
          <div v-if="false" class="d-flex ga-4 align-center mt-4 pa-2 border rounded">
            <h3>BOL</h3>
            <div class="w-100">
              <!-- <% if @order.bol_id && doc = Models::OrderDocument.find_by(id: @order.bol_id) %> -->
              <!-- check for the above condition TODO -->
              <div v-if="false" class="d-flex justify-space-between">
                <a>
                  doc.filename 
                </a>
                <v-icon icon="mdi-trash-can"></v-icon>
              </div>

              <div v-else class="d-flex justify-space-between flex-wrap w-100">
                <v-file-input 
                  ref="bolFileInput"
                  density="compact" 
                  variant="outlined" 
                  hide-details 
                  label="Upload BOL" 
                  class="w-100"
                />

                <v-btn 
                  color="primary"
                  class="mt-2 mx-auto" 
                  variant="outlined" 
                  :loading="uploadingBol" 
                  size="small" 
                  @click="uploadBol"
                >
                  Upload BOL
                </v-btn>
              </div>
            </div>
          </div>

          <div class="d-flex justify-center">
            <v-btn 
              color="primary"
              variant="flat"
              density="comfortable"
              class="mt-4"
              @click="updateAppt"
            >
              Update Appointment
            </v-btn>
          </div>
        </v-card-text>

        <v-card-text v-else>
          <v-table class="appt-detail-table">
            <tbody>
              <tr>
                <th>Appt Id:</th>
                <td> {{ appt.id }}</td>
              </tr>
              <tr>
                <th>Status:</th>
                <td>{{ appt.status.toUpperCase() }}</td>
              </tr>
              <tr>
                <th>Carrier:</th>
                <td>{{ appt.carrier_id }}</td>
              </tr>
              <tr>
                <th>Window Date:</th>
                <td>{{ appt?.window_date }}</td>
              </tr>
              <tr>
                <th>Start Time:</th>
                <td> {{ appt?.start_time }} </td>
              </tr>
              <tr>
                <th>Bypass LTL:</th>
                <td>{{ appt?.bypass_ltl }}</td>
              </tr>
            </tbody>
          </v-table>
        </v-card-text>
      </v-col>

      <v-divider vertical></v-divider>

      <v-col cols="12" md="6" lg="8" >
        <v-card-text>
          <div class="text-h6 border-b-sm border-opacity-50">History</div>

          <!-- TODO WHEN HISTORY API IS READY -->
          <v-table class="history-table">
            <tbody>
                <tr>
                  <td><small>created_at</small></td>
                  <td>user.name</td>
                  <td>
                    <strong v-if="true">Date:</strong> window_date<br />
                    <strong v-if="true">Time:</strong> window_start<br />
                    <strong v-if="true">Carrier:</strong> carrier_id<br />
                    <strong v-if="true">Door:</strong> door_id<br />
                  </td>
                </tr>
            </tbody>
          </v-table>

        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<style scoped>
.history-table, .appt-detail-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px;
}

.appt-detail-table tbody tr td, .appt-detail-table tbody tr th {
  border-bottom: 1px solid #B5B5B5 !important;
}

.history-table tbody tr td {
  border-bottom: 1px solid #B5B5B5 !important;
  padding: 0.5rem !important;
}

</style>